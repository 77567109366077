import React from 'react'
import SidebarChat from './SidebarChat'
import Chat from './Chat'
import './StyleChat.css'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

export default function Principal() {
    return (
        <div>
            <Navbar />
            <Sidebar />
            <div className="home">
                <div className="container">
                    <SidebarChat />
                    <Chat />
                </div>
            </div>
        </div>
    )
}
