import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA72U2ejF1O2vGNcwALBFc3itEI1PNJQog",
    authDomain: "chat-e4572.firebaseapp.com",
    projectId: "chat-e4572",
    storageBucket: "chat-e4572.appspot.com",
    messagingSenderId: "338975197679",
    appId: "1:338975197679:web:9fa7af74bbaa457f684e1a"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore(app, { experimentalForceLongPolling: true, useFetchStreams: false, useRestEmulator: false });
