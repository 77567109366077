import React from 'react'
import NavbarChat from './NavbarChat'
import Search from './Search'
import Chats from './Chats'

export default function SidebarChat() {
    return (
        <div className='sidebar'>
            <NavbarChat />
            <Search />
            <Chats />
        </div>
    )
}
