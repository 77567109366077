import React from 'react';
import './Navbar.css';
import logo from './img/logo-banner.png';
import logo_user from './img/logo-naturalsafe.png';

function Navbar() {
  return (
    <nav className='nav-bar navbar-expand-lg'>
      <div className="nav-header">
        <ul className="navbar-nav mr-auto">
          <li className="nav-buttons">
            <div data-toggle="buttons-radio">
              <img src={logo} alt='Logo Sibo' width='100px' height='40px' />
            </div>
          </li>
        </ul>
      </div>

      <div className="nav-user"><a>| Cerrar sesión</a> </div>
      <div className="nav-user"><a> Natural Safe</a></div>
      <img className="nav-icon" src={logo_user} alt="Logo Sibo" />
    </nav>
  )
}

export default Navbar