import React from 'react'

const Chats = () => {
    return (
        <div className="chats">
            <div className='userChat'>
                <img className='imgChats' src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="user" />
                <div className='userChatInfo'>
                    <span>Natural Safe</span>
                    <p>Gestionar contato de Jean Eddy</p>
                </div>
            </div>

            <div className='userChat'>
                <img className='imgChats' src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="user" />
                <div className='userChatInfo'>
                    <span>Sibo Digital</span>
                    <p>Problema con el SII</p>
                </div>
            </div>

            <div className='userChat'>
                <img className='imgChats' src="https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="user" />
                <div className='userChatInfo'>
                    <span>Romaní</span>
                    <p>Contabilizar factura</p>
                </div>
            </div>



        </div>)
}

export default Chats