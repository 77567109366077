import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Navbar from "./components/Navbar";
import Nosotros from "./components/Nosotros";
import Home from "./pages/Home";
import Login from "./components/login/Login";
import Sidebar from "./components/Sidebar";
import Register from "./components/login/Register";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Principal from "./components/Principal";
const client = new WebSocket("ws://localhost:3000/");

const router = createBrowserRouter([


  {
    path: "/",
    element: (
      <div>
        <Sidebar />
        <Navbar />
        <Home />
      </div>
    ),
  },
  {
    path: "about",
    element: <App />,
  },
  {
    path: "nosotros",
    element: <Nosotros />,
  },
  {
    path: "chat",
    element: <Principal />,
  },
  {
    path: "login",
    element: <Login />,
  }, {
    path: "register",
    element: <Register />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);