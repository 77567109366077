import React from 'react'
import './Login.css'
import logo_user from '../img/logo-banner.png';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
    Navigate
} from "react-router-dom";

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'



const Login = () => {
    return (

        <div className='principal'>
            <div className='container-login'>
                <form className='formulario'>
                    <div className='titulo'>
                        <h1>Login</h1>
                    </div>
                    <input className='loginiput' type="text" placeholder='Username' />
                    <input className='loginiput' type="password" placeholder='Password' />
                    <button className='btnlogin' type="submit" >login</button>
                    <p>¿No estás registrado? Registrate <Link to="../register">aquí </Link></p>
                    <img className='logobanner' src={logo_user} alt="" />
                </form>

            </div>
        </div >
    )
}

export default Login