import React from 'react'

const Message = () => {
    return (
        <div className='message'>
            <div className='messageInfo'>
                <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                <span>Justo ahora</span>

            </div>
            <div className='messageContent'>
                <p>Hola</p>

            </div>
        </div>
    )
}

export default Message