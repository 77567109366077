import React from 'react'

const Search = () => {
    return (
        <div className='search'>
            <div className='searchform'>
                <input className='inputSearch' type='text' placeholder='Buscar Ticket' />
            </div>
            <div className='userChat'>
                <img className='imgChats' src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="user" />
                <div className='userChatInfo'>
                    <span>Natural Safe</span>
                </div>
            </div>
        </div>
    )
}

export default Search