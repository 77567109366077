import React from 'react'
import './Sidebar.css';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";

function Sidebar() {
    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <div id="sidebar" onMouseOver={abrir} onMouseLeave={cerrar}>
                        <ul>

                            <li className="sidebar-btn3">
                                <img src="img/logo-banner.png" alt="Logo Sibo" width="100px" height="40px" />
                            </li>
                            <li className="sidebar-btn2">
                                <a href="nosotros" className="nav-link">
                                    <div className="texto_boton">Nuevo Ticket</div>
                                </a>
                            </li>
                            <li className="sidebar-btn">
                                <Link to='nosotros' className="nav-link">
                                    <div className="texto_boton">Tickets Pendientes</div></Link>
                            </li>
                            <li className="sidebar-btn">
                                <Link to='nosotros' className="nav-link">
                                    <div className="texto_boton">Resumen</div></Link>
                            </li>
                            <li className="sidebar-btn">
                                <Link to='nosotros' className="nav-link">
                                    <div className="texto_boton">Configuracion</div></Link>
                            </li>
                            <li className="sidebar-btn">
                                <Link to='nosotros' className="nav-link">
                                    <div className="texto_boton">Salir</div></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )


    function abrir() {
        let side = document.getElementById("sidebar");
        side.classList.add("active");
    }

    function cerrar() {
        let side = document.getElementById("sidebar");
        side.classList.remove("active");
    }
}

export default Sidebar