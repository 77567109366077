import React from 'react'
import './StyleChat.css'

const NavbarChat = () => {
    return (
        <div className="navbarchat">
            <span className="logo2">Sibo</span>
            <div className="user">
                <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="user" />
                <span>Natural</span>
                <button>Logout</button>
            </div>
        </div>
    )
}

export default NavbarChat