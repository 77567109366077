import React from 'react'
import Card from '../components/Card';
import './Home.css';

function Home() {
    return (
        <div><hr />
            <div className="container-principal">
                <h1>Estadísticas de usuario 📊</h1>
                <hr />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="row">
                                <div className="col-md-4">
                                    <Card
                                        color='blue'
                                        val='16'
                                        detail='Tickets Nuevos'
                                        link='about'
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Card
                                        color='orange'
                                        val='11'
                                        detail='Tickets Pendientes'
                                        link='nosotros'
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Card
                                        color='red'
                                        val='29'
                                        detail='Tickets del Mes'
                                        link='about'
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Card
                                        color='darkblue'
                                        val='33'
                                        detail='Tickets Vencidos'
                                        link='about'
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Card
                                        color='green'
                                        val='12'
                                        detail='Tickets Promedio / día'
                                        link='about'
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Card
                                        color='purple'
                                        val='2.342'
                                        detail='Histórico de Tickets'
                                        link='about'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Home