import React from 'react'
import Messages from './Messages';
import Input from './Input';


export const Chat = () => {
    return (
        <div className='chat'>

            <div className='chatInfo'>
                <span>Ticket #123 | Natural Safe | 12-12-2023 | </span>
                <div className='chatIcons'>
                </div>
            </div>
            <Messages />
            <Input />
        </div>
    )
}

export default Chat;