import './App.css';
import React from "react";
import Navbar from './components/Navbar';


function App() {
  return (

    <div>
      <Navbar />
      <div className="container">
        <h1>que pajo</h1>
        <hr />
      </div>
    </div>

  );
}

export default App;
