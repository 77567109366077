import React from 'react'

const Input = () => {
    return (
        <div className='input'>
            <input type='text' placeholder='Escriba un mensaje' />
            <button>Enviar</button>


        </div>
    )
}

export default Input