import React from 'react'
import './Card.css';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";

function Card(props) {
    return (
        <div className="card">
            <h5 className={`card-header-${props.color}`}>{props.val}</h5>
            <div className='card-body'>
                <p className='card-text'>{props.detail}</p>
            </div>
            <Link to={`${props.link}`} className='card-footer'>Ver Más</Link>
        </div >
    )
}

export default Card
